import React from 'react';
import { Button } from 'antd';
import styles from './index.module.less';

// import { Message, Icon } from 'semantic-ui-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slice';
import { useLocation } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import logo from '../../images/tata_logo.svg';
import leftImage from '../../images/doc2.svg';
//import right from '../../images/Group 11.svg';
import { CheckCircleFilled } from '@ant-design/icons';

const LoginPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  // const [errorMsg] = useState('');
  const dispatch = useDispatch();
  let location = useLocation();

  const data = [
    {
      value: 'Draft and create LoIs/LoAs',
      icon: <CheckCircleFilled style={{ fontSize: '20px' }} />
    },
    {
      value: 'Seek and manage approvals',
      icon: <CheckCircleFilled style={{ fontSize: '20px' }} />
    },
    {
      value: 'Maintain audit trail data',
      icon: <CheckCircleFilled style={{ fontSize: '20px' }} />
    }
  ];

  const signin = () => {
    const user_details = LocalStorage.getItem('LOI_user');
    console.log(user_details ? user_details : 'No response');
    instance
      .loginPopup({
        scopes: ['User.Read'],
        prompt: 'select_account'
      })
      .then((result) => {
        //console.log('step1', result);
        return dispatch(login({ token: result.accessToken }));
      })
      .then((result) => {
        //console.log('step2', result);
        if (result.payload.success) {
          LocalStorage.setItem('LOI_user', JSON.stringify(result.payload));
          const user = result.payload;
          if (user?.user?.Role?.role === 'REQUESTER') {
            location.state?.from?.pathname
              ? history.push(`${location.state?.from?.pathname}`)
              : history.push('/dashboard');
          } else if (user?.user?.Role?.role === 'APPROVER') {
            location.state?.from?.pathname
              ? history.push(`${location.state?.from?.pathname}`)
              : history.push('/approver-dashboard');
          } else if (user?.user?.Role?.role === 'CFO') {
            location.state?.from?.pathname
              ? history.push(`${location.state?.from?.pathname}`)
              : history.push('/CFO-dashboard');
          } else if (user?.user?.Role?.role === 'SBGHEAD') {
            location.state?.from?.pathname
              ? history.push(`${location.state?.from?.pathname}`)
              : history.push('/approver-dashboard');
          } else if (user?.user?.Role?.role === 'REVIEWER') {
            location.state?.from?.pathname
              ? history.push(`${location.state?.from?.pathname}`)
              : history.push('/approver-dashboard');
          } else if (user?.user?.Role?.role === 'ADMIN') {
            location.state?.from?.pathname
              ? history.push(`${location.state?.from?.pathname}`)
              : history.push('/admin-manage');
          }
          //   history.push('/users');
        } else {
          //console.log('$$$$$$$$$$$$$$$$$$$');
        }
      })
      .catch((err) => {
        console.log('noresponse', err, err);
        isAuthenticated
          ? instance.logoutRedirect({
              account: LocalStorage.homeAccountId,
              postLogoutRedirectUri: '/',
              mainWindowRedirectUri: '/'
            })
          : '';
      });
  };

  const LeftScreen = () => {
    return (
      <div className={styles.left_side}>
        <img src={leftImage} alt="image" className={styles.responsive_image} />
      </div>
    );
  };

  const RightList = () => {
    return (
      <div className={styles.list_container}>
        {data.map((item, i) => (
          <li className={`mb-3 font-20 ${styles.list}`} key={i}>
            <div className={styles.list_item}>
              <span className={styles.list_icon}>{item.icon}</span>
              <span className={styles.list_text}>{item.value}</span>
            </div>
          </li>
        ))}
      </div>
    );
  };

  const RightScreenHeaders = () => {
    return (
      <>
        <h1 className={`sfprotext-bold ${styles.heading}`}>Welcome back!</h1>
        <p className={`sfprotext-medium ${styles.subtitle}`}>
          Manage all LoI/LoA documents at one place
        </p>
      </>
    );
  };

  const RightScreen = () => {
    return (
      <div className={styles.right_side}>
        <div className={styles.centered_div}>
          <RightScreenHeaders />
          <RightList />
          <div className={styles.button_container}>
            <Button
              icon={<LogoutOutlined className="mr-2" />}
              className={`mt-3 ${styles.secondary_button}`}
              id="microsoft-login"
              onClick={signin}
            >
              <b className="sfprotext-bold">Go to your Account</b>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const LogoContainer = () => {
    return (
      <div className={`${styles.logo_container}`}>
        <img className={styles.logo} src={logo} alt="tata-logo" />
      </div>
    );
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.second_container}`}>
        <LogoContainer />
        <LeftScreen />
        <RightScreen />
      </div>
    </div>
  );
};

export default LoginPage;
